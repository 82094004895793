'use client';
import { useAppSelector } from '@/store';
import { Drawer, DrawerProvider, InputSelectOption } from '@unique/component-library';
import { IconArrowDown } from '@unique/icons';
import { useState, MouseEvent } from 'react';

type Props = {
  options: InputSelectOption[];
  handleSelectOption?: (option: InputSelectOption) => void;
  title: string;
};

export default function SelectDrawer({ options, handleSelectOption, title }: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const translateToLanguage = useAppSelector(({ translation }) => translation.translateToLanguage);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLLIElement;
    handleSelectOption(options.find((option) => option.value === target.dataset.value));
    setIsDrawerOpen(false);
  };

  return (
    <DrawerProvider>
      <div
        role="dialog"
        onClick={() => setIsDrawerOpen(true)}
        className="border-control flex w-full items-center rounded-lg border px-4 py-2"
      >
        <span className="text-on-control-main pr-16">{translateToLanguage.label}</span>
        <IconArrowDown className="text-primary-cta h-4 w-4" />
      </div>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        {title ? (
          <p className="text-on-surface mx-4 ml-8 mt-8 text-xl font-extrabold">{title}</p>
        ) : (
          ''
        )}
        <div className="mt-4">
          <ul onClick={handleClick}>
            {options.map((option) => (
              <ListItem key={option.value} item={option} />
            ))}
          </ul>
        </div>
      </Drawer>
    </DrawerProvider>
  );
}

const ListItem = ({ item }: { item: InputSelectOption }) => {
  return (
    <li
      className="hover:bg-background text-on-surface cursor-pointer list-none py-4 pl-8 font-medium"
      value={item.value}
      data-value={item.value}
    >
      {item.label}
    </li>
  );
};
