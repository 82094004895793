import {
  MessageCreateMutationVariables,
  MessageSubscriptionSubscription,
  SortOrder,
} from '@/@generated/graphql';
import {
  getUseMessagesQueryKey,
  useMessageCreateMutation,
  useMessagesQuery,
  useMessagesUpdateSubscription,
} from '@/lib/swr/hooks';
import { logger } from '@unique/next-commons/logger';
import { useNavigate } from 'react-router-dom';
import { ToastVariant, useToast } from '@unique/shared-library';
import { GraphQLError } from 'graphql';
import { useEffect, useMemo, useState } from 'react';
import { useRoles } from '@unique/next-commons/authorization';

type Props = {
  currentChatId?: string;
  onError?: () => void;
};

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:translation-component',
});

export default function useChatStream({ currentChatId, onError }: Props) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [subscriptionData, setSubscriptionData] = useState<MessageSubscriptionSubscription>(null);
  const { allowDebugRead } = useRoles();
  const chatQueryVariables = {
    chatId: currentChatId,
    orderBy: [{ createdAt: SortOrder.Asc }],
  };

  const {
    data: messages,
    isLoading: loadingMessages,
    mutate,
  } = useMessagesQuery(
    currentChatId ? chatQueryVariables : null,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
    allowDebugRead,
  );

  const chatId = useMemo(
    () => (typeof currentChatId === 'string' ? currentChatId : ''),
    [currentChatId],
  );

  const clientWs = useMessagesUpdateSubscription(
    {
      next: (data) => {
        setSubscriptionData(data);
      },
      error: (errors: GraphQLError[]) => {
        log.error(`Message update subscription error. Error: ${JSON.stringify(errors)}`);
      },
      complete: () => {
        log.info('Message update subscription complete');
      },
    },
    chatId ? { chatId } : null,
  );

  useEffect(() => {
    // Client WS takes couple seconds to connect, and lose sync with previous call on useMessagesQuery.
    // Trigger an extra mutate to fetch new data. What about overriding mutate call from useMessagesUpdateSubscriptionin // ?
    const removeListener = clientWs.on('connected', () => {
      mutate();
    });
    return () => removeListener();
  }, [clientWs, mutate]);

  const { trigger: createMessage, isMutating: creatingMessage } =
    useMessageCreateMutation(getUseMessagesQueryKey());

  const handleChat = (payload: MessageCreateMutationVariables) => {
    createMessage(payload, {
      revalidate: false,
      throwOnError: false,
      onSuccess: ({ messageCreate }) => {
        navigate(`/${messageCreate.chatId}`);
      },
      onError: (err) => {
        log(err);
        onError?.();
        const errorMessages = err.response?.errors.map((error) => error.message).join(' ');
        showToast({
          message: errorMessages,
          variant: ToastVariant.ERROR,
        });
      },
    });
  };

  return { messages, loadingMessages, handleChat, creatingMessage, subscriptionData } as const;
}
