import { ImportMagicTableColumnsDocument } from '@/@generated/graphql';
import { useImportMagicTableColumns, useMagicTableQuery } from '@/lib/swr/hooks';
import { useAppSelector } from '@/store';
import { logger } from '@unique/next-commons/logger';
import { useEffect } from 'react';

const log = logger.child({
  package: 'chat',
  namespace: 'components:magic-table-component:useMagicTableQuerySubscription',
});

type Props = { sheetId: string };

export default function useDueDiligenceQuery({ sheetId }: Props) {
  const dueDiligencePayload = useAppSelector((state) => state.dueDiligence.dueDiligencePayload);
  const isGridReady = useAppSelector((state) => state.dueDiligence.isGridReady);

  const { trigger: importMagicTableColumns } = useImportMagicTableColumns({
    variables: {},
    query: ImportMagicTableColumnsDocument,
  });

  useEffect(() => {
    if (!dueDiligencePayload?.sheetId || !isGridReady) return;
    importMagicTableColumns({
      sheetId: dueDiligencePayload.sheetId,
      questionFileId: dueDiligencePayload?.importStepContentId
        ? dueDiligencePayload.importStepContentId
        : dueDiligencePayload.chatContent?.[0]?.id,
      sheetName: dueDiligencePayload?.sheetName,
    });
  }, [isGridReady, dueDiligencePayload?.sheetId]);

  const {
    data,
    isLoading: loadingTableData,
    mutate,
  } = useMagicTableQuery(
    { cursor: { id: sheetId } },
    {
      onError: () => {
        log.error('Unable to fetch Magic Table Data');
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      isPaused: () => !sheetId,
    },
  );

  const requeryDueDiligence = () => {
    mutate();
  };

  const dueDiligenceData = data?.getTransformedMagicTableSheet?.magicTableSheet;

  return { dueDiligenceData, loadingTableData, requeryDueDiligence } as const;
}
