import { ChatQuery, MessageCreateMutationVariables, Role } from '@/@generated/graphql';
import { getSupportedLanguageOptions } from '@/helpers/translationTextHelpers';
import useChatStream from '@/hooks/useChatStream';
import { Assistant, ChatAssistant, Message } from '@/lib/swr/types';
import {
  setIsTranslating,
  setTextToTranslate,
  updateTranslateToLanguage,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import {
  BoxInputEnum,
  ButtonIcon,
  ButtonVariant,
  InputSelectOption,
  usePrevious,
} from '@unique/component-library';
import { IconChevronLeft, IconPaperPlane } from '@unique/icons';
import { LayoutContext, ToastVariant, useScreens, useToast } from '@unique/shared-library';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SpaceHeader } from '../Space/SpaceHeader';
import SelectDrawer from './SelectDrawer';
import TranslationBoxInput from './TranslationBoxInput';
import TranslationBoxView from './TranslationBoxView';
import TranslationFeedback from './TranslationFeedback';
import { isSystemMessage } from '@/helpers/messages';
import { useTranslationOneToOneForMessageQuery } from '@/lib/swr/hooks';

type TranslationModeTextProps = {
  assistant: Assistant | ChatAssistant;
  currentChat?: ChatQuery['chat'];
  spaceInfoComponent?: JSX.Element;
};

const DefaultResult = { id: '', text: '', chatId: '', feedbackGiven: null };

export const TranslationModeText: FC<TranslationModeTextProps> = ({
  currentChat,
  assistant,
  spaceInfoComponent,
}) => {
  const navigate = useNavigate();
  const translateToLanguage = useAppSelector(({ translation }) => translation.translateToLanguage);
  const textToTranslate = useAppSelector(({ translation }) => translation.textToTranslate);
  const isTranslating = useAppSelector(({ translation }) => translation.isTranslating);
  const { id } = useParams();

  const [text, setText] = useState(textToTranslate ?? '');
  const [result, setResult] = useState<Partial<Message>>(DefaultResult);
  const [showMobileTranslation, setShowMobileTranslation] = useState(false);
  const toast = useToast();

  const dispatch = useAppDispatch();
  const { isMobile } = useScreens();

  const { setHeaderItems } = useContext(LayoutContext);
  const previous = usePrevious(textToTranslate);

  const updateTextToTranslate = (text: string) => {
    dispatch(setTextToTranslate(text));
  };

  const { messages, handleChat, subscriptionData } = useChatStream({
    currentChatId: currentChat?.id,
    onError: () => {
      dispatch(setIsTranslating(false));
    },
  });

  const shouldLoadTranslationData = id && messages?.messages[0]?.id;

  const { data: translationOneToOne } = useTranslationOneToOneForMessageQuery(
    shouldLoadTranslationData ? { chatId: id, messageId: messages?.messages[0].id } : null,
  );

  useEffect(() => {
    if (!translationOneToOne?.translationOneToOneForMessage) return;
    const initialLanguage = translationOneToOne?.translationOneToOneForMessage.translateToLanguage;
    dispatch(
      updateTranslateToLanguage({
        label: initialLanguage,
        value: initialLanguage,
      }),
    );
  }, [translationOneToOne]);

  const DefaultHeader = [
    <SpaceHeader key={`space-header-${assistant.id}`} title={assistant.name} />,
  ];

  useEffect(() => {
    if (translateToLanguage.value) return;
    dispatch(
      updateTranslateToLanguage(
        getSupportedLanguageOptions(assistant.settings?.translationLanguages)[0],
      ),
    );
  }, [assistant]);

  const handleSelectOption = (selectedOption: InputSelectOption) => {
    dispatch(updateTranslateToLanguage(selectedOption));
    // If there is a current chat, update the translation
    if (result?.text || currentChat?.id) {
      setHeaderItems(DefaultHeader);
      setResult(DefaultResult);
      navigate(`/space/${assistant.id}`);
      // If there is a current chat, update the text to translate
      // to show the previous message in the input box.
      updateTextToTranslate(text);
    }
  };

  useEffect(() => {
    if (currentChat?.title) return;
    if (previous !== text && !text) {
      setResult(DefaultResult);
    }
  }, [text]);

  useEffect(() => {
    if (messages && !subscriptionData) {
      messages?.messages?.map((message) => {
        if (message.role === Role.Assistant) {
          setResult(message);
        }
        if (message.role === Role.User) {
          setText(message.text);
        }
      });
      setShowMobileTranslation(true);
    }
  }, [messages]);

  useEffect(() => {
    if (!subscriptionData) return;
    dispatch(setIsTranslating(false));
    setResult({ ...result, text: subscriptionData?.messageUpdate?.text });
  }, [subscriptionData]);

  const isStreaming =
    !isTranslating &&
    subscriptionData &&
    subscriptionData.messageUpdate?.stoppedStreamingAt === null &&
    !isSystemMessage(subscriptionData.messageUpdate.text);

  const handleTranslate = () => {
    if (isStreaming || isTranslating) {
      toast.showToast({
        message: 'Please wait for the current translation to finish',
        variant: ToastVariant.ERROR,
      });
      return;
    }

    const payload: MessageCreateMutationVariables = {
      input: {
        text,
        role: Role.User,
      },
      assistantId: assistant.id,
      translationInput: {
        translateToLanguage: translateToLanguage.value,
      },
    };
    dispatch(setIsTranslating(true));
    handleChat(payload);
  };

  const clearButtonClicked = () => {
    setResult({ ...result, text: '' });
  };

  return (
    <>
      {isMobile && currentChat?.id ? (
        <div className="border-b-background-variant -mx-4 -mt-4 mb-4 border-b">
          <ButtonIcon
            variant={ButtonVariant.SHAPE}
            icon={<IconChevronLeft width="14px" height="14px" />}
            className="!hover:text-on-background-main !text-on-background-main text-[16px] font-extrabold"
            onClick={() => setShowMobileTranslation(!showMobileTranslation)}
          >
            {showMobileTranslation ? 'Translation' : 'Original Text'}
          </ButtonIcon>
        </div>
      ) : null}
      {isMobile ? (
        <div className="flex h-full w-full">
          {showMobileTranslation || isTranslating ? (
            <TranslationBoxView
              isLoading={isTranslating}
              config={BoxInputEnum.IMMUTABLE}
              handleSelectOption={handleSelectOption}
              languages={assistant?.settings?.translationLanguages}
              result={result}
            />
          ) : (
            <TranslationBoxInput
              config={BoxInputEnum.MUTABLE}
              text={text}
              setText={setText}
              handleTranslate={handleTranslate}
              isLoading={isTranslating}
              onClearButtonClicked={clearButtonClicked}
            />
          )}
        </div>
      ) : (
        <div className="flex h-full w-full gap-5 md:flex-row">
          <TranslationBoxInput
            config={BoxInputEnum.MUTABLE}
            text={text}
            setText={setText}
            handleTranslate={handleTranslate}
            isLoading={isTranslating || isStreaming}
            onClearButtonClicked={clearButtonClicked}
          />
          <TranslationBoxView
            isLoading={isTranslating}
            config={BoxInputEnum.IMMUTABLE}
            handleSelectOption={handleSelectOption}
            className="hidden sm:flex"
            languages={assistant?.settings?.translationLanguages}
            result={result}
          />
        </div>
      )}
      <div className="bg-surface mt-auto flex w-full flex-col py-2">
        {spaceInfoComponent}
        {isMobile && showMobileTranslation ? (
          <>
            {result.text && (
              <div className="mb-3 flex justify-center sm:hidden">
                <TranslationFeedback message={result} />
              </div>
            )}
          </>
        ) : (
          <div className="md:none mb-4 mt-3 md:mt-0">
            <div className="flex w-full items-center justify-between gap-1">
              <div className="flex gap-4 sm:hidden">
                <SelectDrawer
                  options={getSupportedLanguageOptions(assistant?.settings?.translationLanguages)}
                  handleSelectOption={(selectedOption) =>
                    dispatch(updateTranslateToLanguage(selectedOption))
                  }
                  title="Select Language"
                />
              </div>
              <div className="block sm:hidden">
                <ButtonIcon
                  disabled={isTranslating || !text || isStreaming}
                  icon={<IconPaperPlane height="16" width="16" />}
                  onClick={handleTranslate}
                >
                  Translate
                </ButtonIcon>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
