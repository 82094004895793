import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { FC, forwardRef, ReactNode } from 'react';
import SkipDueDiligenceOnboardingButton from './SkipDueDiligenceOnboardingButton';

type Props = {
  assistantId: string;
  onSubmit: (id: string, chat_id: string) => void;
  icon: ReactNode;
  title: string;
  description: string;
  uploadButtonTitle: string;
};

const DueDiligenceAddSource: FC<Props> = forwardRef<HTMLElement, Props>(
  ({ assistantId, onSubmit, icon, description, title, uploadButtonTitle }) => {
    return (
      <div className="mx-5 flex flex-col justify-center">
        <div className="mx-auto w-full max-w-3xl">
          <div className="border-control mx-auto mb-6 mt-10 flex min-h-[480px] w-full items-center justify-center rounded-md border-2 border-dashed">
            <div className="flex flex-col items-center gap-3">
              {icon}
              <p className="text-md text-on-background-main text-center font-bold text-white md:text-2xl">
                {title}
              </p>
              <p className="text-on-background-dimmed text-sm text-white">{description}</p>
              <ButtonIcon variant={ButtonVariant.PRIMARY} onClick={() => onSubmit(null, null)}>
                {uploadButtonTitle}
              </ButtonIcon>
            </div>
          </div>
        </div>
        <SkipDueDiligenceOnboardingButton assistantId={assistantId} />
      </div>
    );
  },
);

export default DueDiligenceAddSource;
