import { getUseCreateMagicTableKey, useCreateMagicTableMutation } from '@/lib/swr/hooks';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { useNavigate } from 'react-router-dom';

export default function SkipDueDiligenceOnboardingButton({ assistantId }: { assistantId: string }) {
  const navigate = useNavigate();
  const { trigger: createMagicTableSheet, isMutating: creatingMagicTableSheet } =
    useCreateMagicTableMutation(getUseCreateMagicTableKey());

  const onSkip = () => {
    createMagicTableSheet(
      { assistantId },
      {
        onSuccess: (data) => {
          navigate(`/space/${assistantId}/${data.createMagicTableSheet.id}`);
        },
      },
    );
  };

  return (
    <div className="flex justify-end">
      <ButtonIcon
        variant={ButtonVariant.TERTIARY}
        onClick={onSkip}
        isLoading={creatingMagicTableSheet}
      >
        Skip
      </ButtonIcon>
    </div>
  );
}
