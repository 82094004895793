'use client';

import { ButtonIcon, ButtonSize, ButtonVariant, ModalProps } from '@unique/component-library';
import { IconCopy } from '@unique/icons';
import { useState } from 'react';
import { CopyToClipboardWrapper } from '@unique/component-library';
import FeedbackButtons from '../FeedbackButtons';
import { IconThumbsDown, IconThumbsUp } from '@unique/icons';
import { FeedbackModal } from '../Chat/FeedbackModal';
import { Modal } from '@unique/component-library';
import { MessageUpdateInput, SortOrder } from '@/@generated/graphql';
import {
  getUseMessagesQueryKey,
  getUseMessagesWithDebugInfoQueryKey,
  useMessageUpdateMutation,
} from '@/lib/swr/hooks';
import { logger } from '@unique/next-commons/logger';
import { Message } from '@/lib/swr/types';
import { useRoles } from '@unique/next-commons/authorization';

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:translation-box-view',
});

export default function TranslationFeedback({ message }: { message: Partial<Message> }) {
  const chatQueryVariables = {
    chatId: message.chatId,
    orderBy: [{ createdAt: SortOrder.Asc }],
  };

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<ModalProps | null>(null);
  const [feedbackGiven, setFeedbackGiven] = useState(null);
  const { allowDebugRead } = useRoles();

  const { trigger: updateMessage } = useMessageUpdateMutation(
    allowDebugRead
      ? getUseMessagesWithDebugInfoQueryKey(chatQueryVariables)
      : getUseMessagesQueryKey(chatQueryVariables),
  );

  const updateMessageData = (messageId: string, input: MessageUpdateInput) => {
    const payload = {
      chatId: message.chatId,
      messageId,
      input,
    };
    updateMessage(payload, {
      revalidate: false,
      throwOnError: false,
      onError: (err) => {
        log(err);
      },
      onSuccess: () => {
        setFeedbackGiven({ positive: input.feedback.create.positive });
      },
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalContent(null);
  };

  const onThumbsClick = (messageId: string, isPositive?: boolean) => {
    const modalContent = {
      title: 'Provide additional feedback',
      icon: isPositive ? (
        <IconThumbsUp className="text-success-dark" height="26" width="24" />
      ) : (
        <IconThumbsDown className="text-error-dark mt-1" height="26" width="24" />
      ),
      children: (
        <FeedbackModal
          isPositive={isPositive}
          messageId={messageId}
          updateMessageData={updateMessageData}
          handleClose={handleModalClose}
        />
      ),
    };
    setModalContent(modalContent);
    setShowModal(true);
  };

  return (
    <>
      <div className="relative flex gap-3">
        <FeedbackButtons
          feedback={
            typeof message?.feedback?.positive !== 'undefined' ? message.feedback : feedbackGiven
          }
          onThumbsClick={onThumbsClick}
          id={message.id}
        />
        <CopyToClipboardWrapper text={message.text}>
          {({ copiedText }) => (
            <ButtonIcon
              icon={<IconCopy />}
              className="!bg-background-variant !ml-auto !flex"
              variant={ButtonVariant.SECONDARY}
              buttonSize={ButtonSize.SMALL}
            >
              {`${copiedText ? 'Copied' : 'Copy'}`}
            </ButtonIcon>
          )}
        </CopyToClipboardWrapper>
      </div>
      {/* Modals */}
      <div
        tabIndex={-1}
        className={`pointer-events-none fixed z-50 opacity-0 transition-opacity ${
          showModal ? 'pointer-events-auto opacity-100' : ''
        }`}
      >
        {modalContent && (
          <Modal
            title={modalContent.title}
            icon={modalContent.icon}
            shouldShow={showModal}
            handleClose={handleModalClose}
          >
            {modalContent.children}
          </Modal>
        )}
      </div>
    </>
  );
}
