'use client';
import { ChatQuery } from '@/@generated/graphql';
import { PoweredByDisclaimer } from '@/components/PoweredByDisclaimer';
import { SpaceInfo } from '@/components/Space/SpaceInfo';
import { useContentByChatQuery } from '@/lib/swr/hooks';
import { Assistant, ChatAssistant } from '@/lib/swr/types';
import { setTextToTranslate, updateTranslateToLanguage, useAppDispatch } from '@/store';
import { ButtonIcon, ButtonVariant, DRAWER_POSITION, Spinner } from '@unique/component-library';
import { IconDocument, IconTranslate } from '@unique/icons';
import {
  FeatureFlagContext,
  isIngestingContent,
  LayoutContext,
  NEW_TRANSLATION_MODULE_NAME,
  REFRESH_INTERVAL_INGESTION,
} from '@unique/shared-library';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChatHeader from '../Chat/ChatHeader';
import { SpaceHeader } from '../Space/SpaceHeader';
import { TranslationModeDocument } from './TranslationModeDocument';
import { TranslationModeText } from './TranslationModeText';

type Props = {
  assistant: Assistant | ChatAssistant;
  currentChat?: ChatQuery['chat'];
};

export enum TranslationMode {
  TEXT = 'TEXT',
  DOCUMENT = 'DOCUMENT',
}
export default function TranslationComponent({ assistant, currentChat }: Props) {
  const [isIngesting, setIsIngesting] = useState<boolean>(false);
  const navigate = useNavigate();
  const [translationMode, setTranslationMode] = useState<TranslationMode>();
  const dispatch = useAppDispatch();
  const { setHeaderItems } = useContext(LayoutContext);
  const { flags } = useContext(FeatureFlagContext);
  const [searchParams] = useSearchParams();

  const updateTextToTranslate = (text: string) => {
    dispatch(setTextToTranslate(text));
  };

  // only execute query once chatId (e.g. id) is available
  // add refresh interval as soon as one content is ingesting
  // remove refresh interval once all content is either finished or failed
  const { data: content, isLoading: isLoadingContent } = useContentByChatQuery(
    currentChat?.id
      ? {
          chatId: currentChat?.id,
        }
      : null,
    { revalidateOnFocus: false, refreshInterval: isIngesting ? REFRESH_INTERVAL_INGESTION : 0 },
  );

  // check if any content is ingesting
  useEffect(() => {
    if (isLoadingContent) return;
    if (!content?.contentByChat?.length) {
      setTranslationMode(TranslationMode.TEXT);
      return;
    }
    setTranslationMode(TranslationMode.DOCUMENT);
    const isAnyContentIngesting = content.contentByChat.some((content) =>
      isIngestingContent(content.ingestionState),
    );
    setIsIngesting(isAnyContentIngesting);
  }, [isLoadingContent, content]);

  useEffect(() => {
    const initialTranslationMode = searchParams.get('mode');
    if (!initialTranslationMode) return;
    setTranslationMode(
      initialTranslationMode.toUpperCase() === TranslationMode.DOCUMENT
        ? TranslationMode.DOCUMENT
        : TranslationMode.TEXT,
    );
  }, [searchParams]);

  const DefaultHeader = [
    <SpaceHeader key={`space-header-${assistant.id}`} title={assistant.name} />,
  ];

  const navigateToSpacePage = () =>
    navigate(
      `/space/${assistant.id}${translationMode ? `?mode=${translationMode.toLowerCase()}` : ''}`,
    );

  const clearTranslation = () => {
    if (currentChat) {
      navigateToSpacePage();
    }
    setHeaderItems(DefaultHeader);
    dispatch(updateTranslateToLanguage({ label: 'English', value: 'English' }));
  };

  useEffect(() => {
    if (currentChat) {
      setHeaderItems([
        <ChatHeader
          key={assistant.id}
          onClickNewChat={navigateToSpacePage}
          assistantTitle={assistant.title}
          customTitle={currentChat?.id ? currentChat?.title : ''}
          buttonText="New Translation"
          resetHeader={clearTranslation}
          drawerPosition={DRAWER_POSITION.TOP}
        />,
      ]);
      return;
    }
    setHeaderItems(DefaultHeader);
    return () => {
      setHeaderItems([]);
      // Clear the text to translate when the component is unmounted
      // And when a user moves from one chat to another
      updateTextToTranslate('');
    };
  }, [assistant, setHeaderItems, currentChat]);

  const getExplanation = (data: ChatAssistant | Assistant) =>
    'explanation' in data ? data.explanation : '';
  const getAlert = (data: ChatAssistant | Assistant) => ('alert' in data ? data.alert : '');

  const handleChangeTranslationMode = (translationMode: TranslationMode) => {
    setTranslationMode(translationMode);
  };

  const SpaceInfoComponent = () => {
    return (
      <div className="flex">
        {!currentChat?.id && (
          <SpaceInfo explanation={getExplanation(assistant)} alert={getAlert(assistant)} />
        )}
      </div>
    );
  };

  const isTranslatorV2 = assistant.modules.some(
    (module) => module.name === NEW_TRANSLATION_MODULE_NAME,
  );

  if (!translationMode) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mx-auto flex h-[calc(100vh-130px)] max-w-[1440px] flex-col sm:h-[calc(100vh-90px)]">
      <div className="bg-surface mt-5 flex h-full w-full flex-col px-5 sm:mt-6 sm:px-10">
        {!currentChat && flags.FEATURE_FLAG_IN_DOCUMENT_TRANSLATOR_UN_8405 && isTranslatorV2 ? (
          <section id="translationModes" className="mb-6 flex items-center gap-x-4">
            <ButtonIcon
              icon={<IconTranslate />}
              variant={
                translationMode === TranslationMode.TEXT
                  ? ButtonVariant.PRIMARY
                  : ButtonVariant.SECONDARY
              }
              onClick={() => handleChangeTranslationMode(TranslationMode.TEXT)}
            >
              Text
            </ButtonIcon>
            <ButtonIcon
              icon={<IconDocument />}
              variant={
                translationMode === TranslationMode.DOCUMENT
                  ? ButtonVariant.PRIMARY
                  : ButtonVariant.SECONDARY
              }
              onClick={() => handleChangeTranslationMode(TranslationMode.DOCUMENT)}
            >
              Document
            </ButtonIcon>
          </section>
        ) : null}

        {translationMode === TranslationMode.TEXT ? (
          <TranslationModeText
            currentChat={currentChat}
            assistant={assistant}
            spaceInfoComponent={<SpaceInfoComponent />}
          />
        ) : (
          <TranslationModeDocument
            currentChat={currentChat}
            assistant={assistant}
            content={content?.contentByChat}
            spaceInfoComponent={<SpaceInfoComponent />}
            isLoadingContent={isLoadingContent}
          />
        )}
        <div className="bg-surface mt-auto flex w-full flex-col py-2">
          <PoweredByDisclaimer />
        </div>
      </div>
    </div>
  );
}
