'use client';

import { formatSupportEmail } from '@/helpers/formatSupportEmail';
import { removeSystemPrefixFromMessage } from '@/helpers/messages';
import { getSupportedLanguageOptions } from '@/helpers/translationTextHelpers';
import { Message } from '@/lib/swr/types';
import { useAppSelector } from '@/store';
import {
  BoxInputEnum,
  InputSelect,
  InputSelectOption,
  MarkdownPreview,
  Spinner,
  TextBox,
} from '@unique/component-library';
import { ClientThemeContext, useScreens } from '@unique/shared-library';
import { useContext } from 'react';
import TranslationFeedback from './TranslationFeedback';

type Props = {
  config: BoxInputEnum;
  isLoading: boolean;
  className?: string;
  languages?: string[];
  result?: Partial<Message>;
  handleSelectOption: (option: InputSelectOption) => void;
};

const TranslationBoxView = ({
  config,
  className,
  isLoading,
  languages,
  result,
  handleSelectOption,
}: Props) => {
  const { isMobile } = useScreens();
  const translateToLanguage = useAppSelector(({ translation }) => translation.translateToLanguage);
  const { supportEmail } = useContext(ClientThemeContext);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="relative flex items-center justify-between md:mt-0">
        <div className="mb-3 mt-3 hidden sm:mt-0 sm:block">
          <InputSelect
            options={getSupportedLanguageOptions(languages)}
            handleSelectOption={handleSelectOption}
            selectedOption={translateToLanguage}
            id="translate"
          />
        </div>
      </div>
      {result?.text?.startsWith('[SYSTEM]') ? (
        <div className="bg-background h-full overflow-auto rounded-lg">
          <MarkdownPreview
            text={removeSystemPrefixFromMessage(formatSupportEmail(result.text, supportEmail))}
            className="border-background bg-background text-on-background-main border py-3"
          />
        </div>
      ) : (
        <TextBox
          placeholder={isLoading ? '' : 'Translation'}
          loader={
            isLoading && (
              <div className="text-on-background-dimmed flex">
                <Spinner />
                &nbsp;Translating...
              </div>
            )
          }
          showClearButton={false}
          value={isLoading ? '' : result?.text}
          setValue={() => null}
          config={config}
          onChange={() => null}
          className={className}
          actions={
            result?.id && !isLoading && !isMobile && <TranslationFeedback message={result} />
          }
        />
      )}
    </div>
  );
};

export default TranslationBoxView;
