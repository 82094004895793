'use client';

import { LayoutContext } from '@unique/shared-library';
import { useParams, useSearchParams } from 'react-router-dom';
import { FC, MouseEventHandler, useContext, useEffect, useRef, useState } from 'react';
import ChatInput from '../Chat/ChatInput';
import { SpaceHeader } from './SpaceHeader';
import { SpaceInfo } from './SpaceInfo';
import { SpaceNotFoundPage } from './SpaceNotFoundPage';
import { AssistantQuery, ChatUpload } from '@/@generated/graphql';
import ChatDropzone from '../ChatDropzone';
import { IconUploadInChat } from '@unique/icons';
import { ButtonIcon } from '@unique/component-library';
import { getAcceptableFileTypes } from '@/helpers/getAcceptableFileTypes';

type SpacePageProps = {
  adminUrl: string;
  chatUrl: string;
  assistant: AssistantQuery['assistantByUser'];
};

const ChatComponent: FC<SpacePageProps> = ({ adminUrl, chatUrl, assistant }) => {
  const [selectedPrompt, setSelectedPrompt] = useState<{ prompt: string } | null>(null);
  const [searchParams] = useSearchParams();
  const { spaceId } = useParams<{ spaceId: string }>();
  const { setHeaderItems, setSplitPaneContent } = useContext(LayoutContext);

  const dropzoneRef = useRef<HTMLElement>(null);
  const cameraInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSplitPaneContent(null);
  }, [spaceId, setSplitPaneContent]);

  useEffect(() => {
    const prompt = searchParams.get('prompt');
    if (!prompt?.length) return;

    setSelectedPrompt({ prompt });
  }, [searchParams]);

  useEffect(() => {
    if (assistant) {
      setHeaderItems([<SpaceHeader key={`space-header-${assistant.id}`} title={assistant.name} />]);
    }
    return () => {
      setHeaderItems([]);
    };
  }, [assistant, setHeaderItems, adminUrl]);

  if (!assistant) {
    return <SpaceNotFoundPage basePath={chatUrl} />;
  }

  const chatUploadEnabled = assistant?.chatUpload === ChatUpload.Enabled;

  const handleChatUploadClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (event.button !== 0) return; // If it's not left click, ignore.

    const uploadInput = dropzoneRef.current.querySelector('#dropzone') as HTMLInputElement;
    if (!uploadInput) {
      console.error('Upload input not found.');
      return;
    }
    uploadInput.click();
  };

  const handleCameraClick = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
  };

  const handleCameraCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = dropzoneRef.current?.querySelector('#dropzone') as HTMLInputElement;
    if (fileInput && e.target.files?.length) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(e.target.files[0]);
      fileInput.files = dataTransfer.files;
      fileInput.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  const acceptableFileTypes = getAcceptableFileTypes(assistant);

  return (
    <div className="mx-auto flex h-[calc(100vh-119px)] max-w-[928px] flex-col px-4 sm:h-[calc(100vh-81px)]">
      <div className="bg-surface relative flex h-full flex-col">
        <SpaceInfo explanation={assistant?.explanation} alert={assistant?.alert} />
        
        {chatUploadEnabled && (
          <ChatDropzone ref={dropzoneRef} assistant={assistant} disableClick={true}>
            <div className="flex h-full flex-col items-center justify-center gap-3">
              <div className="flex w-5/6 flex-row items-center gap-3 sm:flex-col sm:items-center md:w-full">
                <div className="flex sm:ml-0 sm:flex-none">
                  <IconUploadInChat height="80px" width="80px" />
                </div>
                <div className="flex flex-col items-start text-left sm:items-center md:text-center">
                  <p className="text-on-background-main font-extrabold">
                    Upload files to chat with your content
                  </p>
                  <p className="text-on-background-dimmed hidden sm:block">{acceptableFileTypes}</p>
                </div>
              </div>
              <div className="flex flex-row gap-5">
              <ButtonIcon 
                  className="bg-primary-cta text-on-primary md:hidden z-20"
                  onClick={handleCameraClick}
                >
                  Take Photo
                </ButtonIcon>
                <input
                  ref={cameraInputRef}
                  type="file"
                  id="camera-capture"
                  accept="image/*"
                  capture="environment"
                  className="hidden"
                  onChange={handleCameraCapture}
                />
             
                <ButtonIcon className="bg-primary-cta text-on-primary" onClick={handleChatUploadClick}>
                  Upload Files
                </ButtonIcon>
              </div>
            </div>
          </ChatDropzone>
        )}
        <div className="sticky bottom-0 mt-auto">
          <ChatInput
            selectedPrompt={selectedPrompt}
            currentChatAssistant={assistant}
            chatUploadEnabled={chatUploadEnabled}
            handleChatUploadClick={handleChatUploadClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
