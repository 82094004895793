import { ButtonIcon, ButtonVariant, InputSelect } from '@unique/component-library';
import { IconClose, IconViewFolders } from '@unique/icons';
import { magicTableColumns } from './tableColumns';

export default function DueDiligenceAddQuestionButton({
  onColumnSelect,
  setShowQuestionsSelect,
  showQuestionsSelect,
}: {
  onColumnSelect: (value: string) => void;
  setShowQuestionsSelect: (show: boolean) => void;
  showQuestionsSelect: boolean;
}) {
  const questionOptions = magicTableColumns.map((question) => ({
    label: question.title,
    value: question.question,
    labelRaw: question.title,
  }));

  return (
    <>
      {showQuestionsSelect ? (
        <div className="w-full">
          <div className="relative">
            <InputSelect
              id="modulesSelection"
              placeholder="What other information would you like to extract?"
              options={questionOptions}
              handleSelectOption={(option) => onColumnSelect(option.value)}
              tabIndex={4}
              hideArrow
              canCreate
              autoFocus
              createLabel="Add question:"
            />
            <div className="absolute right-2.5 top-2.5 h-full">
              <span className="cursor-pointer" onClick={() => setShowQuestionsSelect(false)}>
                <IconClose width="20px" height="20px" />
              </span>
            </div>
          </div>
        </div>
      ) : (
        <ButtonIcon
          variant={ButtonVariant.SECONDARY}
          icon={<IconViewFolders />}
          className="justify-start text-nowrap"
          onClick={() => setShowQuestionsSelect(!showQuestionsSelect)}
        >
          Add Question
        </ButtonIcon>
      )}
    </>
  );
}
