export const getFileTitleFromKey = (key: string) => {
  if (!key) return '';

  const parts = key.split('_');

  if (parts.length >= 6) {
    // Handle the format with translation prefix and date-time
    const prefix = parts.slice(0, 2).join('_');
    const fileNameWithExt = parts.slice(5).join('_');
    return `${prefix}_${fileNameWithExt}`;
  } else if (parts.length >= 4) {
    // Handle the format without translation prefix
    const fileNameWithExt = parts.slice(3).join('_');
    return fileNameWithExt;
  }

  return '';
};
