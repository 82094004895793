import { CreateMagicTableSheetMutation, getSdk, OwnerType } from '@/@generated/graphql';
import { getUseContentByChatQueryKey, useContentUpsertByChatMutation } from '@/lib/swr/hooks';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';
import { ButtonIcon, ButtonVariant, Dropzone, ProgressBar } from '@unique/component-library';
import { clientContextValues } from '@unique/next-commons/swr';
import {
  getMimeTypeVisuals,
  SUPPORTED_MIME_PDF_DOCS_CSV,
  ToastVariant,
  useFileUpload,
  useToast,
} from '@unique/shared-library';
import cn from 'classnames';
import { FC, forwardRef, MouseEvent, ReactNode, useContext, useEffect, useState } from 'react';
import { ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';
import SkipDueDiligenceOnboardingButton from './SkipDueDiligenceOnboardingButton';

type Props = {
  assistantId: string;
  onSubmit: (id: string, chat_id: string) => void;
  icon: ReactNode;
  title: string;
  description: string;
  handleShowFolderPathSelection?: () => void;
};

const DEFAULT_BORDER_COLOR = 'border-control';

const DueDiligenceUploadBox: FC<Props> = forwardRef<HTMLElement, Props>(
  ({ assistantId, onSubmit, icon, description, title, handleShowFolderPathSelection }, ref) => {
    const maxFiles = 1;
    const { maxFileSize } = useContext(ConfigurationContext);
    const { showToast } = useToast();
    const [borderColor, setBorderColor] = useState(DEFAULT_BORDER_COLOR);

    const [acceptedFiles, setAcceptedFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    // UPSERT CONTENT
    const { trigger: upsertContent } = useContentUpsertByChatMutation(
      getUseContentByChatQueryKey({
        chatId: '',
      }),
    );

    // Upload File
    const { files, handleUpload } = useFileUpload<OwnerType>({
      setError: (error) => showToast({ message: error, variant: ToastVariant.ERROR }),
      chatOwnerType: OwnerType.Chat,
      upsertContent,
      ownerType: OwnerType.Chat,
      upsertContentAttributeName: 'contentUpsertByChat',
      storeInternally: true,
      maxFiles: maxFiles,
      maxFileSize: maxFileSize,
    });

    useEffect(() => {
      // Each update of files will update the progress
      files && files[0] && setProgress(files[0].progress);
    }, [files, acceptedFiles]);

    const handleChatUpload = async (
      acceptedFiles: FileWithPath[],
      fileRejections: FileRejection[],
    ) => {
      if (fileRejections.length) {
        const code = fileRejections[0].errors[0].code;
        let message = `Unknown Error (${code})`;
        switch (code) {
          case ErrorCode.FileTooLarge:
            message = `File too large. The current limit is ${maxFileSize / 1024 / 1024} MB per file.`;
            break;
          case ErrorCode.FileInvalidType:
            message = 'Invalid File Type';
            break;
          case ErrorCode.FileTooSmall:
            message = 'File size is too small';
            break;
          case ErrorCode.TooManyFiles:
            message = `Too many files. You can upload a maximum of ${maxFiles} files at once.`;
            break;
        }
        showToast({ message, variant: ToastVariant.ERROR });
        setBorderColor(DEFAULT_BORDER_COLOR);
        return;
      }

      // Create Magic Table
      const sdkDefault = getSdk(clientContextValues.defaultClient);
      const contentIngestionStateQuery: CreateMagicTableSheetMutation =
        await sdkDefault.CreateMagicTableSheet({
          assistantId,
        });
      const { id, chatId } = contentIngestionStateQuery.createMagicTableSheet;
      try {
        // Update UI with accepted files, hardcoded to 1 file
        setAcceptedFiles(acceptedFiles);

        const startTime = new Date().getTime();
        await handleUpload(acceptedFiles, fileRejections, { newChatId: chatId });
        const stopTime = new Date().getTime();
        // Add timeout 1000 to let the progress bar animation finish
        setTimeout(
          () => {
            // Confirm upload and go to next page
            onSubmit(id, chatId);
          },
          Math.max(0, 1000 - (stopTime - startTime)),
        );
      } catch (error) {
        console.error('Upload failed:', error);
        setAcceptedFiles(null);
        showToast({ message: 'Upload failed. Please try again.', variant: ToastVariant.ERROR });
        setBorderColor(DEFAULT_BORDER_COLOR);
        return;
      }
    };

    const FileTypeIcon = getMimeTypeVisuals(acceptedFiles?.[0]?.type, false);

    const handleClickSelectFromKnowledgeBase = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleShowFolderPathSelection();
    };

    return (
      <div className="mx-5 flex flex-col justify-center">
        <div className="mx-auto w-full max-w-3xl">
          {acceptedFiles ? (
            <>
              <div
                onDragOver={() => setBorderColor('border-primary-cta')}
                onDragLeave={() => setBorderColor(DEFAULT_BORDER_COLOR)}
                className={cn({
                  'mx-auto mt-10 flex min-h-[480px] w-full items-center justify-center rounded-md border-2 border-dashed':
                    true,
                  [borderColor]: true,
                })}
              >
                <div className="flex flex-col items-center gap-3">
                  <FileTypeIcon width="90px" height="60px" />
                  <p className="text-md text-on-background-main text-center font-bold text-white md:text-2xl">
                    Uploading {acceptedFiles[0].name}...
                  </p>
                  <ProgressBar title="Uploading" completed={progress} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Dropzone
                ref={ref}
                onDrop={handleChatUpload}
                onError={(e) => console.log(e)}
                accept={SUPPORTED_MIME_PDF_DOCS_CSV}
                maxFiles={maxFiles}
                maxSize={maxFileSize}
                additonalClassname="h-full pb-2"
              >
                <div
                  onDragOver={() => setBorderColor('border-primary-cta')}
                  onDragLeave={() => setBorderColor(DEFAULT_BORDER_COLOR)}
                  className={cn({
                    'mx-auto mt-10 flex min-h-[480px] w-full items-center justify-center rounded-md border-2 border-dashed':
                      true,
                    [borderColor]: true,
                  })}
                >
                  <div className="flex flex-col items-center gap-3">
                    {icon}
                    <p className="text-md text-on-background-main text-center font-bold text-white md:text-2xl">
                      {title}
                    </p>
                    <p className="text-on-background-dimmed text-sm text-white">{description}</p>
                    <ButtonIcon variant={ButtonVariant.PRIMARY}>Upload File</ButtonIcon>
                    <div className="flex w-full items-center gap-x-3 px-5 py-1">
                      <div className="border-control flex-1 border" />
                      <div className="subtitle-2 text-on-control-dimmed">OR</div>
                      <div className="border-control flex-1 border" />
                    </div>
                    <ButtonIcon
                      variant={ButtonVariant.SECONDARY}
                      onClick={handleClickSelectFromKnowledgeBase}
                    >
                      Select from Knowledge Base
                    </ButtonIcon>
                  </div>
                </div>
              </Dropzone>
              <SkipDueDiligenceOnboardingButton assistantId={assistantId} />
            </>
          )}
        </div>
      </div>
    );
  },
);

export default DueDiligenceUploadBox;
